export class Page {
  page: number
  per_page: number
  sort?: string

  /**
   * Constructs a new Page instance.
   * @param partial - Partial pagination parameters.
   */
  public constructor(partial: Partial<Page> = {}) {
    this.page = partial.page ?? 0       // Default page to 0 if not provided
    this.per_page = partial.per_page ?? 10      // Default size to 10 if not provided
    this.sort = partial.sort             // Sort remains undefined if not provided
  }

  /**
   * Serializes the Page instance into a query string.
   * @returns A query string representing the pagination parameters.
   */
  toQueryString(): string {
    const params: string[] = []
    params.push(`page=${ encodeURIComponent(this.page) }`)
    params.push(`per_page=${ encodeURIComponent(this.per_page) }`)
    if (this.sort) {
      params.push(`sort=${ encodeURIComponent(this.sort) }`)
    }
    return params.join("&")
  }

  /**
   * Converts the Page instance into a URLSearchParams object.
   * @returns A URLSearchParams object representing the pagination parameters.
   */
  toURLSearchParams(): URLSearchParams {
    const params = new URLSearchParams()
    params.append("page", this.page.toString())
    params.append("per_page", this.per_page.toString())
    if (this.sort) {
      params.append("sort", this.sort)
    }
    return params
  }

  /**
   * Converts the Page instance into an object suitable for Axios params.
   * @returns An object representing the pagination parameters.
   */
  toParamsObject(): Record<string, any> {
    const params: Record<string, any> = {
      page: this.page,
      par_page: this.per_page,
    }
    if (this.sort) {
      params.sort = this.sort
    }
    return params
  }
}
