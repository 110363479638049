<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="750px"
    class="right-side-panel"
  >
    <panel-header :show.sync="show">
      Fund budget entity users automatically
    </panel-header>

    <cg-loader v-if="loading"/>

    <div v-else class="right-side-panel__content-wrapper">
      <span class="budget-entity-automation-panel__subtext">
        With this automation you can add funds to the budget entity users automatically based on integration with your HR system.
        <!-- TODO -->
        <a href="/" target="_blank" rel="noopener noreferrer">Learn more</a>
      </span>
      <!-- TODO -->
      <automation-campaign-how-it-works/>

      <template v-if="!isAutoReloadSet">
        <cg-alert type="error" class="budget-entity-automation-panel__alert">
          Auto-reload is required for this budget to be automated. Enabling Auto-reload ensures users always have access
          to their allocated CG Credits.
        </cg-alert>

        <span class="budget-entity-automation-panel__subtext">
          To enable automation, <span @click="onSetAutoReload">set auto-reload</span> to budget entity
        </span>
      </template>

      <div v-else class="budget-entity-automation-panel__form">
        <div class="budget-entity-automation-panel__step-wrapper">
          <label class="budget-entity-automation-panel__step" for="target-audience">
            <span>1</span>

            Select target audience for automation:
          </label>

          <common-select
            v-model="selectedTarget"
            :height="40"
            id="target-audience"
            item-text="name"
            item-value="code"
            style="max-width: 400px"
            :items="availableTargets"
            :rules="[rules.required]"
          />
        </div>

        <hr-awareness v-if="selectedTarget === 'no-hr'"/>

        <template v-else>
          <div
            v-if="selectedTarget === 'contacts'"
            class="budget-entity-automation-panel__step-wrapper"
          >
            <template v-if="userContactGroups.length">
              <label>Select contacts group/s:</label>

              <table class="budget-entity-automation-panel__checkbox-group">
                <tr v-for="group in userContactGroups" :key="group.id">
                  <td>
                    <cg-checkbox v-model="selectedGroupIds" :input-value="group.id">
                      <span class="budget-entity-automation-panel__checkbox-text" :title="group.name">
                        {{ group.name }}
                      </span>
                    </cg-checkbox>
                  </td>
                  <td>
                    <group-info :group="group" :trigger="selectedTrigger"/>
                  </td>
                </tr>
              </table>
            </template>

            <cg-alert v-else type="error" class="budget-entity-automation-panel__alert">
              <div class="budget-entity-automation-panel__no-contacts">
                You don’t have groups in your contact.

                <router-link :to="{ name: 'Contacts' }">
                  Add groups to contacts
                </router-link>
              </div>
            </cg-alert>
          </div>

          <automation-filters
            v-show="selectedTargetId"
            :automation-id="selectedTargetId"
            :settingsFilters.sync="automationFilters"
          />

          <template v-if="showAutomationTrigger">
            <div class="budget-entity-automation-panel__step-wrapper">
              <label class="budget-entity-automation-panel__step" for="automation-trigger">
                <span>2</span>

                Select automation trigger:
              </label>

              <common-select
                v-model="selectedTrigger"
                :height="40"
                id="automation-trigger"
                item-text="name"
                item-value="code"
                style="max-width: 400px"
                :items="automationTriggers"
                :rules="[rules.required]"
              />
            </div>
          </template>

          <div v-if="isAnniversarySelected" class="budget-entity-automation-panel__step-wrapper">
            <label class="d-flex flex-row align-center" style="gap: 10px">
              Select anniversary range:

              <common-tooltip
                :activator-size="20"
                activator-color="#62646A"
                :z-index="8"
                :max-width="300"
              >
                TODO
              </common-tooltip>
            </label>

            <div class="budget-entity-automation-panel__anniversary-range">
              <common-input
                v-model.number="anniversaryRange.from"
                :height="40"
                suffix="years"
                type="number"
                min="1"
                max="99"
                step="1"
                style="max-width: 84px"
                :rules="[rules.required, rules.greaterThanZero]"
              />

              <span>to</span>

              <common-input
                v-model.number="anniversaryRange.to"
                :height="40"
                suffix="years"
                type="number"
                min="1"
                max="99"
                step="1"
                style="max-width: 84px"
                :rules="[rules.required, rules.greaterThanZero, anniversaryRangeFromMin]"
              />
            </div>
          </div>

          <template v-if="selectedTrigger">
            <div class="budget-entity-automation-panel__step-wrapper">
              <label class="budget-entity-automation-panel__step" for="automation-trigger">
                <span>3</span>

                Add on:
              </label>

              <div class="d-flex flex-row" style="gap: 8px">
                <common-select
                  v-model="selectedNotificationOption"
                  :height="40"
                  top
                  id="notification-trigger"
                  style="max-width: 160px"
                  :items="notificationOptions"
                  :rules="[rules.required]"
                />

                <template v-if="selectedNotificationOption === 'other'">
                  <common-input
                    v-model.number="customNotification.days"
                    :height="40"
                    suffix="Days"
                    type="number"
                    min="1"
                    step="1"
                    style="max-width: 112px"
                    :rules="[rules.required, rules.greaterThanZero]"
                  />

                  <common-select
                    v-model="customNotification.type"
                    :items="notificationTypes"
                    id="notification-type"
                    style="max-width: 112px"
                    :height="40"
                    top
                    :rules="[rules.required]"
                  />
                </template>
              </div>
            </div>
          </template>
          <!-- TODO debug -->
          <template v-if="isNotificationFilled">
            <div class="budget-entity-automation-panel__step-wrapper">
              <label class="budget-entity-automation-panel__step" for="user-balance">
                <span>4</span>

                Add to user’s balance:
              </label>

              <common-input
                v-model="userBalance"
                type="number"
                prefix="$"
                id="user-balance"
                style="max-width: 75px;"
                :height="40"
                :rules="[rules.required, rules.greaterThanZero]"
              />
            </div>
          </template>
          <!-- TODO debug -->
          <template v-if="userBalance">
            <cg-alert
              background-color="#D3D2D2"
              class="budget-entity-automation-panel__alert"
            >
              <template #icon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M11.8573 13.7702C11.5421 13.7702 11.2871 14.0236 11.2871 14.336C11.2871 14.6492 11.5421 14.9025 11.8573 14.9025C13.8059 14.9025 15.3922 16.4776 15.3922 18.4143C15.3922 18.6635 15.1875 18.8669 14.9358 18.8669H1.59534C1.34444 18.8669 1.13976 18.6635 1.13976 18.4143C1.13976 16.4776 2.72603 14.9025 4.67378 14.9025C4.98905 14.9025 5.24407 14.6492 5.24407 14.336C5.24407 14.0236 4.98905 13.7702 4.67378 13.7702C2.09631 13.7702 0 15.8528 0 18.4143C0 19.2883 0.716376 20 1.59534 20H14.9358C15.8156 20 16.5328 19.2883 16.5328 18.4143C16.5328 15.8528 14.4356 13.7702 11.8573 13.7702ZM8.26639 5.13232C9.99461 5.13232 11.4018 6.94271 11.4018 9.16798C11.4018 11.3924 9.99461 13.2036 8.26639 13.2036C6.53735 13.2036 5.131 11.3924 5.12935 9.16798C5.12935 6.94271 6.53652 5.13232 8.26639 5.13232ZM8.26639 14.336C10.6243 14.336 12.5415 12.018 12.5415 9.16798C12.5415 6.31793 10.6243 4 8.26639 4C7.10021 4 6.01079 4.55345 5.20033 5.5595C4.41958 6.52947 3.99041 7.81019 3.99041 9.16798C3.99041 12.018 5.90845 14.336 8.26639 14.336Z"
                        fill="#62646A"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M19.2 3.20003H16.8V0.799966C16.8 0.35849 16.4425 0 16 0C15.5577 0 15.2 0.35849 15.2 0.799966V3.20003H12.8C12.3577 3.20003 12 3.55836 12 4C12 4.44164 12.3577 4.79997 12.8 4.79997H15.2V7.20003C15.2 7.64168 15.5577 8 16 8C16.4425 8 16.8 7.64168 16.8 7.20003V4.79997H19.2C19.6425 4.79997 20 4.44164 20 4C20 3.55836 19.6425 3.20003 19.2 3.20003Z"
                        fill="#62646A"/>
                </svg>
              </template>

              If you're setting up automation for a new employee, the system will automatically create a user on
              CorporateGift.com if one doesn’t already exist.
            </cg-alert>

            <cg-alert
              background-color="#D3D2D2"
              class="budget-entity-automation-panel__alert"
            >
              <template #icon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10 10.625C9.655 10.625 9.375 10.345 9.375 10C9.375 9.65563 9.655 9.375 10 9.375C10.345 9.375 10.625 9.65563 10.625 10C10.625 10.345 10.345 10.625 10 10.625ZM11.6331 10.9012C11.7831 10.6331 11.875 10.3287 11.875 10C11.875 9.18625 11.3506 8.49938 10.625 8.24063V3.125C10.625 2.78 10.3456 2.5 10 2.5C9.65438 2.5 9.375 2.78 9.375 3.125V8.24063C8.64937 8.49938 8.125 9.18625 8.125 10C8.125 11.0337 8.96625 11.875 10 11.875C10.2481 11.875 10.4838 11.8244 10.7006 11.7362L13.3081 14.3431C13.43 14.465 13.59 14.5263 13.75 14.5263C13.91 14.5263 14.07 14.465 14.1919 14.3431C14.4362 14.1 14.4362 13.7044 14.1919 13.46L11.6331 10.9012ZM10 18.75C5.175 18.75 1.25 14.825 1.25 10C1.25 5.175 5.175 1.25 10 1.25C14.825 1.25 18.75 5.175 18.75 10C18.75 14.825 14.825 18.75 10 18.75ZM10 0C4.48625 0 0 4.48625 0 10C0 15.5138 4.48625 20 10 20C15.5138 20 20 15.5138 20 10C20 4.48625 15.5138 0 10 0Z"
                        fill="#62646A"/>
                </svg>
              </template>

              When setting up a funding automation, note that there is no expiration or time limit on the spend limit.
            </cg-alert>

            <cg-checkbox v-model="approveBeforeSending">
              Receive a reminder email before each eGift is sent. Option to cancel available
            </cg-checkbox>

            <div class="budget-entity-automation-panel__buttons">
              <cg-button
                v-if="isAlreadyAutomated"
                outlined
                @click="removeAutomation">
                Remove automation
              </cg-button>

              <cg-button v-else outlined @click="show = false">Cancel</cg-button>

              <div class="budget-entity-automation-panel__buttons">
                <cg-button outlined @click="openPreview">
                  Preview
                </cg-button>

                <span/>

                <cg-button @click="submit">
                  Apply
                </cg-button>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <automation-preview-modal
      v-model="previewDialog"
      :preview-data="upcomingSendsRequest"
    />
  </v-navigation-drawer>
</template>

<script>
  import Api from "@/axios/api"
  import panelVModel from "@/mixins/panelVModel"

  import PanelHeader from "@/components/PanelHeader.vue"
  import {CgAlert, CgButton, CgCheckbox, CgLoader} from "@corporategift/design-system"
  import AutomationCampaignHowItWorks
    from "@/components/myCampaign/panels/campaignAutomation/AutomationCampaignHowItWorks.vue"
  import GroupInfo from "@/components/myCampaign/panels/campaignAutomation/AutomationCampaignPanelGroupInfo.vue"
  import AutomationFilters from "@/components/myCampaign/panels/campaignAutomation/CampaignAutomationFilters.vue"
  import HrAwareness from "@/components/myCampaign/panels/campaignAutomation/AutomationCampaignHrAwareness.vue"

  import {required} from "@corporategift/design-system/validations"

  import {NotificationType, parseOffset, setOffset} from "@/utils/automationNotification"
  import AutomationPreviewModal from "@/components/myCampaign/panels/TheAutomationCampaignPreviewModal.vue"

  export default {
    name: "TheBudgetEntitiesAutomationPanel",
    mixins: [panelVModel],
    components: {
      AutomationPreviewModal,
      AutomationCampaignHowItWorks,
      AutomationFilters,
      PanelHeader,
      HrAwareness,
      CgCheckbox,
      GroupInfo,
      CgButton,
      CgLoader,
      CgAlert,
    },
    props: {
      budgetEntity: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
    data: () => ({
      previewDialog: false,
      loading: false,
      selectedTarget: null,
      selectedTrigger: null,
      approveBeforeSending: false,
      selectedGroupIds: [],
      selectedNotificationOption: null,
      automationFilters: {},
      automationSources: [],
      customNotification: {
        days: null,
        type: null,
      },
      anniversaryRange: {
        from: 1,
        to: 99,
      },
      userContactGroups: [],
      userBalance: null, // new to handle
      noteData: {
        title: "",
        message: "",
      }, // new to handle
      notificationOptions: [
        {
          text: "On exact date",
          value: "0after",
          data: {
            days: 0,
            type: NotificationType.After,
          },
        },
        {
          text: "1 day before",
          value: "1before",
          data: {
            days: 1,
            type: NotificationType.Before,
          },
        },
        {
          text: "2 days before",
          value: "2before",
          data: {
            days: 2,
            type: NotificationType.Before,
          },
        },
        {
          text: "3 days before",
          value: "3before",
          data: {
            days: 3,
            type: NotificationType.Before,
          },
        },
        {
          text: "4 days before",
          value: "4before",
          data: {
            days: 4,
            type: NotificationType.Before,
          },
        },
        {
          text: "5 days before",
          value: "5before",
          data: {
            days: 5,
            type: NotificationType.Before,
          },
        },
        {
          text: "1 day after",
          value: "1after",
          data: {
            days: 1,
            type: NotificationType.After,
          },
        },
        {
          text: "2 days after",
          value: "2after",
          data: {
            days: 2,
            type: NotificationType.After,
          },
        },
        {
          text: "3 days after",
          value: "3after",
          data: {
            days: 3,
            type: NotificationType.After,
          },
        },
        {
          text: "4 days after",
          value: "4after",
          data: {
            days: 4,
            type: NotificationType.After,
          },
        },
        {
          text: "5 days after",
          value: "5after",
          data: {
            days: 5,
            type: NotificationType.After,
          },
        },
        {
          text: "Other",
          value: "other",
          data: null,
        },
      ],
      notificationTypes: Object.values(NotificationType).map((value) => ({
        text: value.charAt(0).toUpperCase() + value.slice(1),
        value,
      })),
      rules: {
        required: required(),
        greaterThanZero: (v) => v > 0 || "",
        noteMessageLength: (v) => v?.length <= 250 || ""
      },
      isAlreadyAutomated: false,
    }),
    computed: {
      upcomingSendsRequest() {
        return {
          source: this.selectedTarget,
          source_id: this.selectedTargetId,
          type: this.selectedTrigger,
          offset: 0,
          anniversary_range: this.anniversaryRange,
          settings: { group_ids: this.selectedGroupIds },
        }
      },
      budgetEntityName() {
        return this.budgetEntity?.name ?? null
      },
      budgetEntityId() {
        return this.budgetEntity?.id ?? null
      },
      isAutoReloadSet() {
        return this.budgetEntity?.is_auto_reload_enabled ?? false
      },
      availableTargets() {
        const { automationSources } = this

        if (automationSources.length > 1) {
          return automationSources
        }
        return [
          ...automationSources,
          {
            code: "no-hr",
            id: null,
            name: "My HR system",
          },
        ]
      },
      showAutomationTrigger() {
        const { selectedTrigger, selectedTarget, selectedGroupIds } = this

        if (selectedTrigger) {
          return true
        }
        if (!selectedTarget) {
          return false
        }
        if (selectedTarget === "contacts") {
          return !!selectedGroupIds.length
        }
        return true
      },
      notificationData() {
        const { selectedNotificationOption, customNotification, notificationOptions } = this

        if (!selectedNotificationOption) {
          return { days: null, type: null }
        }
        if (selectedNotificationOption === "other") {
          return customNotification
        }

        const selectedNotification = notificationOptions.find(({ value }) => value === selectedNotificationOption)
        if (selectedNotification) {
          return selectedNotification.data
        }

        return { days: null, type: null }
      },
      isNotificationFilled() {
        const { notificationData } = this
        return Object.values(notificationData).every((value) => value !== null)
      },
      selectedTargetId() {
        const { selectedTarget, automationSources } = this
        const selectedSource = automationSources.find(({ code }) => code === selectedTarget)

        return selectedSource?.id ?? null
      },
      automationTriggers() {
        const { selectedTarget, automationSources } = this
        const selectedSource = automationSources
          .find(({ code }) => code === selectedTarget) ?? automationSources.at(0)

        return selectedSource?.types ?? []
      },
      isAnniversarySelected() {
        return this.selectedTrigger === "anniversary"
      },
    },
    watch: {
      show: function (val) {
        if (val) {
          Object.assign(this.$data, this.$options.data())

          if (this.isAutoReloadSet) {
            this.loadData()
          }
        }
      },
    },
    methods: {
      openPreview() {
        this.previewDialog = true
      },
      onSetAutoReload() {
        const { budgetEntity } = this
        this.show = false

        this.$nextTick(() => {
          this.$emit("autoReloadEntity", budgetEntity)
        })
      },
      loadData() {
        this.loading = true

        const promises = [
          Api.get("/campaigns/automation/contact-groups")
            .then(({ data }) => data),
          Api.get("/campaigns/automation/sources")
            .then(({ data }) => data),
          Api.get(`/credit/budget-entities/${this.budgetEntityId}/automation`)
            .then(({ data }) => data)
            .catch(() => null)
        ]

        Promise.all(promises)
          .then(async ([contacts, sources, form]) => {
            this.userContactGroups = contacts
            this.automationSources = sources

            if (form) {
              this.isAlreadyAutomated = true
              this.selectedTarget = form.source
              this.selectedTrigger = form.type
              this.approveBeforeSending = form?.is_for_approval ?? false
              this.selectedGroupIds = form?.settings?.group_ids ?? []
              this.anniversaryRange = form?.anniversary_range ?? { from: 1, to: 99 }
              this.userBalance = form?.amount ?? null
              this.noteData = {
                title: form?.note?.title ?? "",
                message: form?.note?.message ?? "",
              }

              const notificationData = parseOffset(form.offset)
              const notificationKey = Object.values(notificationData).join("")
              const notificationOptionIndex = this.notificationOptions
                .findIndex(({ value }) => value === notificationKey)
              const settingsData = {
                ...form.settings,
                group_ids: undefined,
              }

              this.automationFilters = structuredClone(settingsData)

              if (notificationOptionIndex === -1) {
                this.selectedNotificationOption = "other"
                this.customNotification = notificationData
              } else {
                this.selectedNotificationOption = notificationKey
              }
            }
          })
          .finally(() => (this.loading = false))
      },
      anniversaryRangeFromMin(val) {
        if (!val) {
          return true
        }
        return this.anniversaryRange.from <= val
      },
      getRequestBody() {
        const {
          selectedTarget,
          selectedTrigger,
          selectedTargetId,
          selectedGroupIds,
          anniversaryRange,
          automationFilters,
          approveBeforeSending,
          notificationData,
          userBalance,
          noteData,
        } = this

        let settings = {
          group_ids: selectedGroupIds,
        }

        if (selectedTargetId) {
          settings = {
            ...automationFilters,
            group_ids: [],
          }
        }

        return {
          source: selectedTarget,
          source_id: selectedTargetId,
          type: selectedTrigger,
          is_for_approval: approveBeforeSending,
          offset: setOffset(notificationData),
          anniversary_range: anniversaryRange,
          settings,
          amount: userBalance,
          note: noteData,
        }
      },
      submit() {
        this.loading = true
        const requestBody = this.getRequestBody()

        Api.post(`/credit/budget-entities/${this.budgetEntityId}/automation`, requestBody)
          .then(({ success }) => {
            if (success) {
              this.$cgToast.success("Successfully added automation to budget entity")
              this.show = false
              this.$emit("fetchNewData")
            }
          })
          .catch(({ response: { data } }) => {
            const errorMessage = data?.message ?? undefined
            this.$cgToast.error(errorMessage || "An error occurred, please contact our support")
          })
          .finally(() => (this.loading = false))
      },
      removeAutomation() {
        this.loading = true
        Api.delete(`/credit/budget-entities/${this.budgetEntityId}/automation`)
          .then(() => {
            this.$cgToast.success("Successfully removed automation")
            this.show = false
            this.$emit("fetchNewData")
          })
          .catch(({ response: { data } }) => {
            const errorMessage = data?.message ?? undefined
            this.$cgToast.error(errorMessage || "An error occurred, please contact our support")
          })
          .finally(() => (this.loading = false))
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/css/common/right-side-panel.scss';

  .right-side-panel {
    &::v-deep > .v-navigation-drawer__content {
      gap: 36px;
    }

    &__content-wrapper {
      gap: 32px;
    }
  }

  .budget-entity-automation-panel {
    &__title {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      line-height: inherit;

      & > b {
        font-family: 'Lato-Bold', sans-serif;
      }
    }

    &__subtext {
      font-family: 'Lato-Regular', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000;

      & > a {
        color: #6E50FF !important;
      }

      & > span {
        cursor: pointer;
        color: #6E50FF;
      }
    }

    &__form {
      gap: 42px;
      display: flex;
      flex-direction: column;
    }

    &__step {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 16px;
      color: #000;
      font-size: 18px;
      line-height: 21px;
      font-family: 'Lato-Bold', sans-serif;

      & > span {
        font-size: 15px;
        line-height: 18px;
        color: #fff;
        border-radius: 48px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 29px;
        min-width: 29px;
        background-color: #222325;
      }
    }

    &__step-wrapper {
      gap: 24px;
      display: flex;
      flex-direction: column;

      & > label {
        font-family: 'Lato-Bold', sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #000;
      }
    }

    &__checkbox-group {
      table-layout: fixed;
      border: none;
      width: 100%;
      border-collapse: collapse;

      & > tr {
        & > td {
          padding: 15px 0;
          border-top: 1px solid #D3D2D2;

          &:first-child {
            padding-right: 16px;
            width: 200px;
          }
        }

        &:last-of-type > td {
          border-bottom: 1px solid #D3D2D2;
        }
      }
    }

    &__checkbox-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
    }

    &__anniversary-range {
      display: flex;
      gap: 12px;
      flex-direction: row;
      align-items: center;

      & > span {
        font-family: 'Lato-Regular', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #000;
      }
    }

    &__buttons {
      gap: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &::v-deep > button {
        border-radius: 100px;
      }
    }

    &__alert::v-deep > .cg-alert__content {
      font-family: 'Lato-Regular', sans-serif;
      font-weight: 400;
      width: 100%;

      & > a {
        color: #6E50FF !important;
      }
    }

    &__no-contacts {
      display: flex;
      gap: 16px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      & > a {
        font-family: 'Lato-Bold', sans-serif;
        font-weight: 700;
        text-decoration: underline !important;
      }
    }
  }
</style>
