export default {
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: "#62646A"
    }
  }
}
