<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :server-items-length="totalElements"
      :options.sync="options"
      calculate-widths
      disable-sort
      class="spend-limit-table"
      :items-per-page="5"
      :footer-props="{ itemsPerPageOptions: [5, 10, 20] }">
      <template #[`item.user`]="{ item }">
        {{ item.user ? item.user.first_name + " " + item.user.last_name : "-" }}
      </template>

      <template #[`item.amount`]="{ item }">
        {{ NumberUtils.formatUSD(item.amount) }}
      </template>

      <template #[`item.date`]="{ item }">
        {{ DateUtils.formatDate(new Date(item.date)) }}
      </template>

      <template #[`item.by`]="{ item }">
        {{ item.by ? item.by.first_name + " " + item.by.last_name : "-" }}
      </template>

      <template #[`item.balance`]="{ item }">
        {{ NumberUtils.formatUSD(item.balance) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import {DateUtils} from "@/utils/date-utils"
  import {NumberUtils} from "@/utils/number-utils"

  export default {
    name: "SpendLimitReportTable",
    computed: {
      NumberUtils() {
        return NumberUtils
      },
      DateUtils() {
        return DateUtils
      }
    },
    components: {},
    props: {
      items: {
        type: Array,
        required: true,
        default: () => ([])
      },
      totalElements: {
        type: Number,
        required: true,
        default: 0
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      options: {
        pageNumber: 0,
        pageSize: 5,
      },
      headers: [
        { text: "ORDER #", value: "order_number" },
        { text: "DATE", value: "date" },
        { text: "USER", value: "user", width: "16%" },
        { text: "AMOUNT", value: "amount" },
        { text: "TRANSACTION TYPE", value: "transaction_type", width: "18%" },
        { text: "BY", value: "by", width: "16%" },
        { text: "BALANCE PER USER", value: "balance", align: "start", width: "15%" },
      ]
    }),
    mounted() {
    },
    methods: {},
    watch: {
      options: {
        handler: function () {
          this.$emit("fetch-data", this.options)
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
  .spend-limit-table {
    table tr td {
      min-height: 70px !important;
      height: 70px !important;
    }

    tr th {
      border-right: none !important;
    }

    tbody tr {
      &:nth-child(even) {
        background-color: unset;
      }

      &:hover {
        background-color: #fafafa !important;
      }
    }

    &__icon-wrapper {
      display: flex;
      padding: 10px;
      width: fit-content;
      margin-inline: auto;
    }
  }
</style>
