<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.714233 0V18.5714H12.1428V17.1429H2.1428V1.42857H9.28566V5.71429H13.5714V7.14286H14.9999V4.71429L14.7857 4.5L10.4999 0.214286L10.2857 0H0.714233ZM10.7142 2.42857L12.5714 4.28571H10.7142V2.42857ZM3.57138 7.14286V8.57143H12.1428V7.14286H3.57138ZM15.7142 8.57143V10C14.4999 10.2143 13.5714 11.2143 13.5714 12.5C13.5714 13.9286 14.6428 15 16.0714 15H16.7857C17.3571 15 17.8571 15.5 17.8571 16.0714C17.8571 16.6429 17.3571 17.1429 16.7857 17.1429H14.2857V18.5714H15.7142V20H17.1428V18.5714C18.3571 18.3571 19.2857 17.3571 19.2857 16.0714C19.2857 14.6429 18.2142 13.5714 16.7857 13.5714H16.0714C15.4999 13.5714 14.9999 13.0714 14.9999 12.5C14.9999 11.9286 15.4999 11.4286 16.0714 11.4286H18.5714V10H17.1428V8.57143H15.7142ZM3.57138 10.7143V12.1429H8.57138V10.7143H3.57138ZM9.99995 10.7143V12.1429H12.1428V10.7143H9.99995ZM3.57138 13.5714V15H8.57138V13.5714H3.57138ZM9.99995 13.5714V15H12.1428V13.5714H9.99995Z"
      :fill="color"/>
  </svg>

</template>

<script>
  import IconMixin from "@/mixins/icon-mixin"

  export default {
    name: "MoneyDocumentIcon",
    mixins: [IconMixin]
  }
</script>

<style scoped lang="scss">
</style>
