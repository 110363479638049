import Api from '@/axios/api'
import { SpendLimitReportRequest } from "@/services/model/request/spend-limit-report-request";
import { Page } from "@/services/model/page";
import { SpendLimitReportResponse } from "@/services/model/response/spend-limit-report-response";
import spendLimitReportData from '@/assets/fake-data/spend-limit-report-data.json';

export class BudgetEntityService {
  static async fetchSpendLimitReport(request: SpendLimitReportRequest, page: Page, csv = false): Promise<SpendLimitReportResponse> {
    const params: string[] = [];

    if (request.userId !== undefined) {
      params.push(`user_id=${ encodeURIComponent(String(request.userId)) }`);
    }
    if (request.startDate !== undefined) {
      params.push(`date_from=${ encodeURIComponent(String(request.startDate)) }`);
    }
    if (request.endDate !== undefined) {
      params.push(`date_to=${ encodeURIComponent(String(request.endDate)) }`);
    }

    const pageQuery = page.toQueryString();
    if (pageQuery) {
      params.push(pageQuery);
    }

    const queryString = params.join('&');
    const url = csv
      ? `credit/budget-entities/${ request.budgetEntityId }/spend-limit-report/csv?${ queryString }`
      : `credit/budget-entities/${ request.budgetEntityId }/spend-limit-report?${ queryString }`;
    return await Api.get(url);
  }

  static async fetchSpendLimitReportFake(request: SpendLimitReportRequest, page: Page): Promise<SpendLimitReportResponse> {
    // Mocking the API call to return fake data
    return spendLimitReportData as SpendLimitReportResponse;
  }
}
